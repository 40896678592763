import * as React from "react"

type ContextType = {
  locale?: string;
};

export const LocaleContext = React.createContext<ContextType>({});

interface LocaleProviderProps {
  locale: string;
  children: React.ReactNode;
}

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ locale, children }) => {
    return (
        <LocaleContext.Provider value={{ locale }}>
            {children}
        </LocaleContext.Provider>
    );
};




