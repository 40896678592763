import React from "react"
import type { GatsbyBrowser, GatsbySSR } from "gatsby"
import { Script, ScriptStrategy } from "gatsby"
import ShopifyCartProvider from "./src/provider/ShopifyCartProvider"
import LocalCartProvider from "./src/provider/LocalCartProvider"
import AlertProvider from "./src/provider/AlertProvider"
import FirebaseProvider from "./src/provider/FirebaseProvider"
import B2BAuthProvider from "./src/provider/B2BAuthProvider"
import SiteAuthProvider from "./src/provider/SiteAuthProvider"
import { FirebaseAppProvider } from "reactfire"
import { FirebaseServices } from "./src/components/FirebaseServices"
import { IS_B2B } from "./src/utils/config/public"



const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID
  // measurementId: process.env.FIREBASE_MEASUREMENT_ID
}

const omnisendAccountId = process.env.OMNISEND_ACCOUNT_ID
const omnisendShopHostname = process.env.OMNISEND_SHOP_HOSTNAME
const omnisendShopId = process.env.OMNISEND_SHOP_ID
const facebookPixelId = process.env.GATSBY_FACEBOOK_PIXEL_ID


/**
 * Shared `wrapPageElement` and `wrapRootElement` functions to be
 * re-exported in gatsby-browser and gatsby-ssr.
 */

export const wrapRootElement:
  | GatsbyBrowser[`wrapRootElement`]
  | GatsbySSR[`wrapRootElement`] = ({ element }): JSX.Element => (
  <>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseServices>
        <B2BAuthProvider>
          <FirebaseProvider>
            <SiteAuthProvider>
              <LocalCartProvider>
                <ShopifyCartProvider>
                  <AlertProvider>
                    {element}
                  </AlertProvider>
                </ShopifyCartProvider>
              </LocalCartProvider>
            </SiteAuthProvider>
          </FirebaseProvider>
        </B2BAuthProvider>
      </FirebaseServices>
    </FirebaseAppProvider>
    <Script strategy={ScriptStrategy.idle}>{
      `//OMNISEND-SNIPPET-SOURCE-CODE-V1
        window.omnisend = window.omnisend || [];
        omnisend.push(["accountID", "${omnisendAccountId}"]);       
        omnisend.push(["shopType", "shopify"]);       
        omnisend.push(["shopHostname", "${omnisendShopHostname}"]);       
        omnisend.push(["shopID", "${omnisendShopId}"]);       
        !function(){ var e=document.createElement("script");e.type= "text/javascript",e.async=!0,e.src= "https://omnisnippet1.com/inshop/launcher-v2.js"; var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}();`
    }
    </Script>

    {/*<Script strategy={ScriptStrategy.idle}*/}
    {/*        src="https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=14322"/>*/}

    <Script strategy={ScriptStrategy.idle}>{
      `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${facebookPixelId}');
            fbq('track', 'PageView');`
    }
    </Script>


    <Script strategy={ScriptStrategy.idle}>{
      `
      _linkedin_partner_id = "5892506";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`
    }
    </Script>


    <Script strategy={ScriptStrategy.idle}>{
      ` <!-- TikTok Pixel Code -->            
              !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
                )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

                ttq.load('CHDPC4JC77UE3SJV0EMG');
                ttq.page();
            }(window, document, 'ttq');
            `
    }
    </Script>
  </>
)
