import './src/styles/fonts/spectral/index.css'
import './src/styles/fonts/ApercuPro/stylesheet.css'
import './src/styles/b2c.css'
import './src/styles/global.css'

import {
    AnalyticsEventName,
    AnalyticsPageType,
    getClientBrowserParameters, sendShopifyAnalytics, ShopifySalesChannel
} from "@shopify/hydrogen-react"
    ;
import {
    ShopifyAnalytics,
    ShopifyPageViewPayload
} from "@shopify/hydrogen-react/dist/types/analytics-types";


import {ReactNode} from "react";
import {LocaleContext} from "./src/provider/LocaleContext";
import {GatsbyBrowser} from "gatsby";

export {wrapRootElement} from "./gatsby-shared"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = (
    {
        element,
        props
    }) => {
    const {locale} = props.pageContext;
    return (
        <LocaleContext.Provider value={{locale}}>
            {element}
        </LocaleContext.Provider>
    )
}

// @ts-ignore
//todo fix this, get the right type for location
export const onRouteUpdate = ({location}) => {
    const storeUrl = process.env.GATSBY_SHOP_NAME
    const domain = storeUrl + '.myshopify.com'

    const hasUserConsent = true;

    let pageType;
    let send;

    switch (true) {
        case location.pathname.includes('collections'):
            pageType = AnalyticsPageType.collection
            send = true
            break
        case location.pathname.includes('products'):
            pageType = AnalyticsPageType.product
            send = false
            break
        case location.pathname.includes('recipes'):
            pageType = AnalyticsPageType.page
            send = true
            break
        case location.pathname.includes('blogs'):
            pageType = AnalyticsPageType.blog
            send = true
            break
        case location.pathname.includes('search'):
            pageType = AnalyticsPageType.search
            send = true
            break
        default:
            pageType = AnalyticsPageType.home
            send = false
    }


    const analytics = {
        hasUserConsent,
        shopId: 'gid://shopify/Shop/15750603',
        currency: 'AED',
        acceptedLanguage: 'en',
        pageType: pageType,
        shopifySalesChannel: ShopifySalesChannel.headless
    };

    const payload = {
        ...getClientBrowserParameters(),
        ...analytics,
    };


    const shopifyAnalyticsEvent: ShopifyAnalytics = {
        eventName: AnalyticsEventName.PAGE_VIEW,
        payload: payload as ShopifyPageViewPayload
    }


    if (send) {
        sendShopifyAnalytics(
            shopifyAnalyticsEvent, domain).then();
    }

}
