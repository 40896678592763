import React, {useState} from "react";
import {useSigninCheck, useAuth} from "reactfire";
import { navigate } from "gatsby"

export const FirebaseContext = React.createContext({});
export const isBrowser = () => typeof window !== "undefined"

const FirebaseProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [session, setSession] = useState(false);

    const auth = useAuth();
    const {data: signInCheckResult} = useSigninCheck({requiredClaims: {shopifyUser: true}});


    const signOut = () => {
        fetch("/api/auth/sessionLogout", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then(() => {
            auth.signOut().then(() => {
                console.log('signed out')
                setSession(false)
                setUser(null)
                navigate('/')
            });
        }).catch((error) => {
            console.log("firebaseProvider error", error)
        });
    }


    if (isBrowser) {
        const fbUser = signInCheckResult && signInCheckResult.user;

        if (fbUser && fbUser.emailVerified && signInCheckResult.hasRequiredClaims && !session) {
            console.log("user sending sesssion login", fbUser)
            const data = {fbToken: fbUser.accessToken, csrfToken: ""}
            fetch("/api/sessionLogin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }).then(() => {
                console.log("user logged in", fbUser)
                setUser(fbUser)
                setSession(true)
            }).catch((error) => {
                console.log("firebaseProvider error", error)
            });
        }
    }
    return (
        <FirebaseContext.Provider value={{
            user,
            signOut
        }}>{children}</FirebaseContext.Provider>
    )
}

export default FirebaseProvider;