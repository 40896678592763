import React, { useState } from "react"

export const B2bAuthContext = React.createContext({})
export const isBrowser = () => typeof window !== "undefined"

const B2BAuthProvider = ({ children }) => {
  const isB2b = process.env.GATSBY_B2B === "true"
  const [init, setInit] = useState()
  const [user, setUser] = useState(null)

  React.useEffect(() => {
    async function fetchData() {
      const isLogged = await fetch(`/api/customer/logged`, {
        method: "GET"
      })
      if (isLogged.status === 401) {
        console.log("b2b not logged in")
        return null
      } else {
        const response = await isLogged.json()
        console.log("b2b response", response)
        return response
      }
    }

    if (!init && isB2b) {
      fetchData().then(r => {
        console.log("b2b response", r)
        setUser(r)
        setInit(true)
      })
    }

  }, [])

  const signOut = async () => {
    console.log("B2b provider sign out")
    const response = await fetch(`/api/auth/signout`, {
      method: "GET"
    })
    const url = await response.json()
    window.location.href = url.url
  }


  return (
    <B2bAuthContext.Provider value={{ user,signOut }}>
      {children}
    </B2bAuthContext.Provider>
  )
}

export default B2BAuthProvider