const accountUrl = process.env.GATSBY_ACCOUNTS_URL
export const accountProfileUrl = `/${accountUrl}/profile`
export const accountOrdersUrl = `/${accountUrl}/orders`

export const IS_B2B = process.env.GATSBY_B2B === "true"
export const GATSBY_SF_RECORD_TYPE_ID = process.env.GATSBY_SF_RECORD_TYPE_ID
export const GATSBY_SF_OWNER_ID = process.env.GATSBY_SF_OWNER_ID
export const PUBSUB_TOPIC_ID = process.env.PUBSUB_TOPIC_ID



