import React, {useState} from "react";

export const AlertContext = React.createContext({});

const AlertProvider = ({children}) => {

    const [message, setMessage] = useState("");

    const resetMessage = () => {
        setMessage(undefined);
    }

    const handleOpenAlertLimit = (maxOrderLimit, itemTitle) => {
        setMessage(`You cannot add more than ${maxOrderLimit} items of ${itemTitle} to your cart.`)
    }

    return (
        <AlertContext.Provider
            value={{
                handleOpenAlertLimit,
                resetMessage,
                message
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}

export default AlertProvider;
