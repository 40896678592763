import React, { useState } from 'react';
import Currency from 'currency.js';
import { getLocalState, setLocalState } from '../utils/local-storage';
import {addItemToCart,removeItemFromCart} from "../services/eventDispatcher";

export const CART_PRODUCTS = 'cartProducts';
export const TOTAL_PRICE = 'totalPrice';
export const CURRENCY = 'currency';


export const LocalCartContext = React.createContext(
    {products: []}
);

const getCartProducts = () => {
    const products = getLocalState(CART_PRODUCTS);
    return products ? products : [];
};

const getTotalPrice = () => {
    const price = getLocalState(TOTAL_PRICE);
    return price ? price : 0;
};

const getCurrency = () => {
    // const currency = getLocalState(CURRENCY);
    return 'AED';
};

const calculateTotalPrice = (products)  => {
    let total = Currency(0);
    let finalTotal;
    products.forEach((product) => {
        const quantity = product.quantity ? product.quantity : 1;
        const price = product.price ? product.price : '0.0';
        const itemPrice = Currency(quantity).multiply(price);
        total = Currency(total).add(itemPrice);
    });
    finalTotal = Number(total.value);
    return finalTotal;
};



const LocalCartProvider = ({children}) => {
    const [products, setProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [currency, setCurrency] = useState('AED');

    React.useEffect(() => {
        setProducts(getCartProducts());
        setTotalPrice(getTotalPrice());
        setCurrency(getCurrency());
    }, []);

    function tagAddItemToCart(item) {
        addItemToCart(
            item.sku,
            item.variantId,
            item.title,
            !item.prevPrice  ? Number(item.price) : Number(item.prevPrice),
            !item.prevPrice  ? 0 : Number(Math.abs(item.prevPrice - item.price).toFixed(2)),
            item.vendor,
            item.quantity_available,
            item.list_index,
            item.list_id,
            item.list_name,
            item.source_path
        );
    }
    function tagRemoveItemFromCart(item,quantity) {
        removeItemFromCart(
            item.sku,
            item.title,
            !item.prevPrice ? Number(item.price) : Number(item.prevPrice),
            !item.prevPrice ? 0 : Number(Math.abs(item.prevPrice - item.price).toFixed(2)),
            item.vendor,
            quantity);
    }

    const setPrice = () => {
        const price = calculateTotalPrice(products);
        setLocalState(TOTAL_PRICE, price);
        setTotalPrice(price);
    };

    const setCurrencyData = () => {
        const currency = products && products.length ? products[0].currency : null;
        setLocalState(CURRENCY, currency);
        setCurrency(currency);
    };

    const add = (item) => {
        if (products.length) {
            const index = products.findIndex(
                product => product.variantId === item.variantId
            );
            if (index !== -1 && !item.gelato_flavours) {
                // if product already available in the cart
                const product = products[index];
                const quantity = product.quantity ? product.quantity : 0;
                const price = product.price ? product.price : 0;
                const totalPrice = price * quantity;
                products[index] = { ...product, ...item, quantity: quantity + 1,"totalPrice": totalPrice }; // just increase the quantity
                // tagAddItemToCart(product)
            } else {
                // if this product is not available in the cart
                const totalPrice = item.price
                products.push({ ...item, quantity: 1 ,"totalPrice": totalPrice}); // add the product to the cart
                // tagAddItemToCart(item)
            }
        }else {
            // if the cart is empty
            const totalPrice = item.price
            products.push({ ...item, quantity: 1 ,"totalPrice": totalPrice});
            // tagAddItemToCart(item)
        }

        tagAddItemToCart(item)
        setLocalState(CART_PRODUCTS, products);
        setProducts([...products]); // have to use the shallow copy [...products]
        setPrice();
        setCurrencyData();
    }
    const update = (variantId, quantity, currentIndex) => {

        const index = currentIndex === -1 ? products.findIndex(
            product => product.variantId === variantId
        ) : currentIndex;
        if (quantity === 0 && index > -1) {
            // delete if quantity, 0
            const product = products[index];
            const current_quantity = product.quantity
            products.splice(index, 1);
            tagRemoveItemFromCart(product,current_quantity)
        } else {
            // update quantity
            const product = products[index];
            const current_quantity = product.quantity
            const totalPrice = (product.price * quantity).toFixed(2) ;
            products[index] = { ...product, quantity, totalPrice };
            quantity - current_quantity < 0 ? tagRemoveItemFromCart(product,1) : tagAddItemToCart(product)
        }
        setLocalState(CART_PRODUCTS, products);
        setProducts([...products]);
        setPrice();
    }
    const clearCart = () => {
        setLocalState(CART_PRODUCTS, []);
        setLocalState(TOTAL_PRICE, 0);
        setProducts([]);
        setTotalPrice(0);
    }

    const productInCart = (variantId) => {
        const index = products.findIndex(
            product => product.variantId === variantId
        );
        return products[index]
    }

    return (
        <LocalCartContext.Provider
            value={{
                products,
                add,
                update,
                totalPrice,
                currency,
                clearCart,
                productInCart
            }}
        >
            {children}
        </LocalCartContext.Provider>
    );
}


export default LocalCartProvider;
