import {setLocalState} from "../utils/local-storage";
import {
    AnalyticsEventName, AnalyticsPageType,
    getClientBrowserParameters,
    sendShopifyAnalytics,
    ShopifySalesChannel
} from "@shopify/hydrogen-react";

export const isBrowser = typeof window !== 'undefined';

const logEvent = true
const logShopifyEvent = true


function getUserToken() {

    if (window.iuii) {
        return window.iuii
    } else {
        window.aa('getUserToken', null, (err, userToken) => {
            if (err) {
                console.error(err);
                return;
            }
            console.log('getUserToken', userToken);
            return userToken;
        });
    }


}

export const viewPromotion = (promotionName, locationId) => {
    if (isBrowser) {
        // const dataLayer = window.dataLayer || [];
        //
        // dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
        // const ecommerce = {
        //     promotion_name: promotionName,
        //     location_id: locationId,
        //     items: []
        // }
        // dataLayer.push({
        //     event: "view_promotion_v2",
        //     ecommerce: ecommerce
        // });
        //
        // if (logEvent) {
        //     console.log("tagging view_promotion_v2", ecommerce);
        // }
    }
}

export const selectPromotion = (promotionName, locationId) => {
    if (isBrowser) {
        const gtag = window.gtag
        const event = {
            location_id: locationId,
            promotion_name: promotionName,
            items: [],
        }
        if (gtag) {
            gtag("event", "select_promotion", event);
            if (logEvent) {
                console.log("tagging select_promotion", event);
            }
        } else {
            console.log("DEVELOPMENT tagging select_promotion", event);
        }
    }
}

export const addItemToCart = (id, variantId, name, price, discount, brand, quantityAvailable, listIndex, listId, listName, sourcePath) => {
    if (isBrowser) {
        const gtag = window.gtag
        const linktrk = window.lintrk

        const taxonomy = sourcePath ? sourcePath.split("/") : []
        const value = price - discount

        const event = {
            currency: "AED",
            value: value,
            items: [
                {
                    item_id: id,
                    item_name: name,
                    affiliation: "Casinetto",
                    currency: "AED",
                    discount: discount,
                    item_brand: brand,
                    price: price,
                    quantity: 1,
                    index: listIndex || 0,
                    item_list_id: listId || "",
                    item_list_name: listName || "",
                    item_category: taxonomy[0] || "",
                    item_category2: taxonomy[1] || "",
                    item_category3: taxonomy[2] || "",
                    item_category4: taxonomy[3] || "",
                    item_category5: taxonomy[4] || "",
                }
            ]
        }
        if (gtag) {
            gtag("event", "add_to_cart", event);

            if (logEvent) {
                console.log("tagging add_to_cart", event);
            }
        } else {
            console.log("DEVELOPMENT tagging add_to_cart", event);
        }

        if(linktrk){
            window.lintrk('track', { conversion_id: 17130706 });
        }
    }
}

export const removeItemFromCart = (id, name, price, discount, brand, quantity) => {
    if (isBrowser) {
        const gtag = window.gtag
        const value = price - discount
        const event = {
            value: value,
            currency: "AED",
            items: [
                {
                    item_id: id,
                    item_name: name,
                    affiliation: "Casinetto",
                    currency: "AED",
                    discount: discount,
                    index: 0,
                    item_brand: brand,
                    price: price,
                    quantity: quantity
                }
            ]
        }

        if (gtag) {
            gtag("event", "remove_from_cart", event);
            if (logEvent) {
                console.log("tagging remove_from_cart", event);
            }
        } else {
            console.log("DEVELOPMENT tagging remove_from_cart", event);
        }
    }
}

export const viewCart = (itemsArray, totalPrice) => {
    if (isBrowser) {
        const gtag = window.gtag
        const event = {
            currency: "AED",
            value: totalPrice,
            items: itemsArray
        }

        if (gtag) {
            gtag("event", "view_cart", event);

            if (logEvent) {
                console.log("tagging view_cart", event);
            }
        } else {
            console.log("DEVELOPMENT tagging view_cart", event);
        }
    }
}

export const beginCheckout = (itemsArray) => {
    if (isBrowser) {

        const gtag = window.gtag
        const event = {
            items: itemsArray
        }

        if (gtag) {
            gtag("event", "begin_checkout", event);

            if (logEvent) {
                console.log("tagging begin_checkout", event);
            }
        } else {
            console.log("DEVELOPMENT tagging begin_checkout", event);
        }
    }
}

export const selectItem = (id, name, price, discount, brand, quantityAvailable, index, listId, listName, sourcePath="", sku, query) => {
    const taxonomy = sourcePath.split("/")
    if (isBrowser) {

        //todo we need to call shopify anal hrere
        if (query && query.length !== "") {
            window.aa('clickedObjectIDsAfterSearch', {
                index: 'shopify_products',
                eventName: 'Click item',
                queryID: query,
                objectIDs: [id],
                positions: [index + 1],
                userToken: getUserToken()
            });
        }

        const gtag = window.gtag
        const event = {
            items: [
                {
                    item_id: sku,
                    item_name: name,
                    affiliation: "Casinetto",
                    currency: "AED",
                    discount: discount,
                    index: index,
                    item_brand: brand,
                    price: price,
                    quantity: quantityAvailable || 0,
                    item_list_id: listId || "na",
                    item_list_name: listName || "na",
                    item_category: taxonomy[0] || "",
                    item_category2: taxonomy[1] || "",
                    item_category3: taxonomy[2] || "",
                    item_category4: taxonomy[3] || "",
                    item_category5: taxonomy[4] || "",
                }
            ]
        }

        if (gtag) {
            gtag("event", "select_item", event);

            if (logEvent) {
                console.log("tagging select_item", event);
            }
        } else {
            console.log("DEVELOPMENT tagging select_item", event);
        }
        setLocalState("cas_ga", {select_item: event.items[0]});
    }
}

export const viewItemList = (itemsArray) => {
    if (isBrowser) {
        const gtag = window.gtag
        const event = {
            items: itemsArray
        }
        if (gtag) {
            gtag("event", "view_item_list", event);

            if (logEvent) {
                console.log("tag view_item_list", event);
            }
        } else {
            console.log("DEVELOPMENT tagging view_item_list", event);
        }
    }
}

export const viewItemDetails = (shopifyProductId,shopifyVariantId,id, name, price, discount, brand, quantity, index, itemListId, itemListName, itemCategory, itemCategory2, itemCategory3) => {
    if (isBrowser) {
        const gtag = window.gtag

        const event = {
            items: [
                {
                    item_id: id,
                    item_name: name,
                    affiliation: "Casinetto",
                    currency: "AED",
                    discount: discount,
                    item_brand: brand,
                    price: price,
                    quantity: quantity || 0,

                    index: index || 0,
                    item_list_id: itemListId || "",
                    item_list_name: itemListName || "",
                    item_category: itemCategory || "",
                    item_category2: itemCategory2 || "",
                    item_category3: itemCategory3 || "",

                }
            ]
        }

        if (gtag) {
            gtag("event", "view_item", event);

            if (logEvent) {
                console.log("tag view_item", event)
            }
        } else {
            console.log("DEVELOPMENT tagging view_item", event);
        }


        const shopifyPageAnalytics = {
            resourceId: shopifyProductId,
            products: [{
                productGid:shopifyProductId,
                variantGid:shopifyVariantId,
                name:name,
                price:parseFloat(price),
            }],
            totalValue: parseFloat(price),
        }

        if (logEvent) {
            console.log("Shopify event", shopifyPageAnalytics)
        }

        sendToShopify(AnalyticsEventName.PAGE_VIEW,AnalyticsPageType.product, shopifyPageAnalytics)
    }
}

export const signUp = (method) => {
    if (isBrowser) {
        const gtag = window.gtag

        const event = {
            method: method
        }
        if (gtag) {
            gtag("event", "sign_up", event);
        }
    }
}

export const login = (method) => {
    if (isBrowser) {
        const gtag = window.gtag
        const event = {
            method: method
        }
        if (gtag) {
            gtag("event", "login", event);
        }
    }
}

export const search = (searchTerm) => {
    if (isBrowser) {
        const gtag = window.gtag
        const event = {
            search_term: searchTerm
        }
        if (gtag) {
            gtag("event", "search", event);
        }
    }
}


const sendToShopify = (eventName,pageType, pageAnalytics) => {
    const hasUserConsent = true;

    const analytics = {
        hasUserConsent,
        shopId: 'gid://shopify/Shop/15750603',
        currency: 'AED',
        acceptedLanguage: 'en',
        pageType: pageType,
        shopifySalesChannel: ShopifySalesChannel.headless,
        ...pageAnalytics
    };

    const payload = {
        ...getClientBrowserParameters(),
        ...analytics,
    };

    if (logShopifyEvent) {
        sendShopifyAnalytics({
            eventName: eventName,
            payload,
        }).then(r => {
            console.log("sent analytics to shopify")
        });
    }else{
        console.log("DEVELOPMENT sent analytics to shopify ", eventName, payload)
    }

}


